<template>
  <v-dialog v-model="open" max-width="500px">
    <UserInfo
      v-if="open"
      :pTitle="pCardTitle"
      :pUser="userRequestedToEdit"
      :pMode="pDisabled ? viewMode : editMode"
      :pLoading="loading"
      :pOptions="options"
      @update="onUpdateUser"
    >
      <template v-slot:actions>
        <v-spacer />
        <v-btn text @click="open = false">Cancel</v-btn>
        <v-btn
          v-if="!pDisabled"
          class="save-btn"
          text
          color="primary"
          :disabled="!isDirty || !isValid"
          @click="saveUserAndCloseDialog"
        >Save</v-btn>
      </template>
    </UserInfo>
  </v-dialog>
</template>

<script>
import Vue from "vue";
import UserInfo from "@/components/user/UserInfo";
import { UserInfoMode } from "@/components/user/user-info-types";
import { UserService } from "@/services/user.service";
import { dismissAction, logAndExtractMessage } from "@/utils/utils";

export default {
  name: "UserDialog",

  components: {
    UserInfo,
  },

  props: {
    pStoreRef: String,
    pCardTitle: String,
    pDisabled: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    userRequestedToEdit: undefined,
    updatedUser: undefined,
    open: false,
    loading: false,
    isDirty: false,
    isValid: false,
    viewMode: UserInfoMode.VIEW,
    editMode: UserInfoMode.EDIT,
    options: undefined,
  }),

  methods: {
    onUpdateUser(data) {
      this.isDirty = data.isDirty;
      this.isValid = data.isValid;
      this.updatedUser = data.user;
    },

    openDialog(user, options) {
      this.userRequestedToEdit = user || {};
      this.options = options || {};
      this.open = true;
    },

    async saveUserAndCloseDialog() {
      this.loading = true;
      try {
        await UserService.updateUser(this.updatedUser, this.userRequestedToEdit, this.pStoreRef);
        this.$emit("onAddOrSave", this.updatedUser);
        this.open = false;
      } catch (e) {
        Vue.toasted.error(logAndExtractMessage(e), { ...dismissAction });
      }
      this.loading = false;
    },
  },
};
</script>
